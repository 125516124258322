'use strict';

$(function () {
  var pull = $('.pull');
  var hamburger = $('.hamburger');
  var menu = $('nav ul');

  $(pull).on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation(); //otherwise it clicks twice...

    hamburger.toggleClass('is-active');
    menu.slideToggle();
  });
});

$(function () {
  $.scrollUp({
    // defaults:
    // scrollName: 'scrollUp',      // Element ID
    // scrollFrom: 'top',           // 'top' or 'bottom'
    // scrollSpeed: 300,            // Speed back to top (ms)
    // easingType: 'linear',        // Scroll to top easing (see http://easings.net/)
    // animation: 'fade',           // Fade, slide, none
    // animationSpeed: 200,         // Animation speed (ms)
    // scrollTrigger: false,        // Set a custom triggering element. Can be an HTML string or jQuery object
    // scrollTarget: false,         // Set a custom target element for scrolling to. Can be element or number
    // scrollText: 'Scroll to top', // Text for element, can contain HTML
    // scrollTitle: false,          // Set a custom <a> title if required.
    // scrollImg: false,            // Set true to use image
    // activeOverlay: false,        // Set CSS color to display scrollUp active point, e.g '#00FFFF'
    // zIndex: 2147483647           // Z-Index for the overlay
    scrollName: 'scroll-up', // Element ID
    scrollDistance: 50, // Distance from top/bottom before showing element (px)
    activeOverlay: false, // Set CSS color to display scrollUp active point, e.g '#00FFFF'
    scrollImg: true, // Set true to use image
    hideWhenStill: true, // Hide element after scroll stops
    hideHesitation: 1000,
  });
});
